// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import '@fortawesome/fontawesome-free/js/all'
import toastr from 'toastr'
import Tagify from '@yaireo/tagify'
import Swal from 'sweetalert2'
import IntroJs from 'intro.js/intro.js'

import { Modal, Popover, Sidebar } from '@coreui/coreui'
import 'select2/dist/js/select2'

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('jquery')

window.jQuery = $
window.Tagify = Tagify
window.Swal = Swal
window.IntroJs = IntroJs

toastr.options.closeButton = true
toastr.options.showMethod = 'slideDown'
toastr.options.progressBar = true
window.toastr = toastr

document.addEventListener('turbolinks:load', function () {
  $('*[data-table="datatable"]').DataTable()

  Array.from(document.querySelectorAll('[data-toggle="popover"]'))
    .forEach(popoverNode => new Popover(popoverNode))

  Array.from(document.querySelectorAll('.c-sidebar')).forEach(element => {
    Sidebar._sidebarInterface(element)
  })

  $('.js-select2').select2()

  const modalForm = document.getElementById('modalForm')
  if (modalForm) {
    window.modalForm = new Modal(modalForm, { keyboard: false })
  }
})
